import { Badge, Box, Grid2, Popover } from "@mui/material";
import { Button, DateRangePicker, SelectBox } from "../../common";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import {
  DateRange,
  DropdownItem,
  FilterItem,
  PaymentRecordsFilters,
} from "../../../types";
import {
  paymentsFilterStateAtom,
  selectedOrgIdAtom,
  userAtom,
} from "../../../atoms";
import { useAtom } from "jotai";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { getDateRange } from "../../../utils/dateUtil";
import { DATE_RANGE_OPTIONS } from "../../../constants";
import moment, { Moment } from "moment";
import {
  useFetchAccountsQuery,
  useFetchOrganizationsQuery,
} from "../../../api";
import { AccountModel, OrganizationModel } from "../../../models";
import Loading from "../../common/loading/Loading";
import { Filter } from "../../common/filter";
import { theme } from "../../../utils";
import { MdOutlineFilterAlt } from "react-icons/md";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { DateRangeOptions, PaymentStatus, Role } from "../../../enums";
import { useSearchParams } from "react-router-dom";
import { Search } from "../../search";

type PaymentsToolBarProps = {
  onRefresh?: () => void;
  onDownload?: () => void;
  resetPage: () => void;
  selectedDateRange: string;
  setSelectedDateRange: React.Dispatch<React.SetStateAction<string>>;
  paymentRecordsFilters: PaymentRecordsFilters;
  setPaymentRecordsFilters: React.Dispatch<
    React.SetStateAction<PaymentRecordsFilters>
  >;
};

export const PaymentsToolBar = ({
  onRefresh,
  onDownload,
  resetPage,
  selectedDateRange,
  setSelectedDateRange,
  paymentRecordsFilters,
  setPaymentRecordsFilters,
}: PaymentsToolBarProps) => {
  const [user] = useAtom(userAtom);
  const [selectedOrgId, setSelectedOrgId] = useAtom(selectedOrgIdAtom);
  const [queryParams] = useSearchParams();
  const [selectedOrgIdFilter, setSelectedOrgIdFilter] = useState(
    user?.orgId || 0
  );
  const [searchTerm, setSearchTerm] = useState("");

  const fetchAccountsQuery = useFetchAccountsQuery(selectedOrgIdFilter, {
    enabled: selectedOrgIdFilter ? true : false,
  });
  const fetchOrganizationsQuery = useFetchOrganizationsQuery({
    enabled: user?.roleCode === Role.SuperAdmin,
  });

  const [organizationsDropdown, setOrganizationsDropdown] = useState<
    DropdownItem[]
  >([]);
  const [accountsDropdown, setAccountsDropdown] = useState<DropdownItem[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isFilterOpen = Boolean(anchorEl);

  const filters = useMemo<FilterItem[]>(() => {
    const baseFilters: FilterItem[] = [
      {
        type: "list",
        label: "Account",
        values: accountsDropdown,
        keyword: "accountId",
      },
      {
        type: "list",
        label: "Status",
        values: [
          { key: "All", value: PaymentStatus.All },
          { key: "Payment Success", value: PaymentStatus.PaymentSuccess },
          { key: "Payment Failed", value: PaymentStatus.PaymentFailed },
        ],
        keyword: "paymentStatus",
      },
    ];

    if (user?.roleCode === Role.SuperAdmin) {
      return [
        {
          type: "list",
          label: "Organization",
          values: organizationsDropdown,
          keyword: "orgId",
        },
        ...baseFilters,
      ];
    }

    return baseFilters;
  }, [user?.roleCode, organizationsDropdown, accountsDropdown]);

  const [filterValues, setFilterValues] = useAtom(paymentsFilterStateAtom);

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const dateRanges: DropdownItem[] = [
    ...DATE_RANGE_OPTIONS.map((option) => ({
      key: option,
      value: option.replace(/\s+/g, "").replace(/^\w/, (c) => c.toLowerCase()),
    })),
  ];

  const handleOnFilterChange = (changedFilter: { [key: string]: any }) => {
    const keyName = Object.keys(changedFilter)[0];
    if (keyName === "accountId") {
      setFilterValues((prev) => ({
        ...prev,
        [keyName]: changedFilter?.accountId,
      }));
    }
    if (keyName === "orgId") {
      setFilterValues((prev) => ({
        ...prev,
        [keyName]: changedFilter.orgId,
      }));
    }
  };

  const handleDateChange = (selectedDateRange: string) => {
    resetPage();
    setSelectedDateRange(selectedDateRange);
    if (selectedDateRange !== "customDate") {
      const dateRange: DateRange = getDateRange(selectedDateRange);
      setPaymentRecordsFilters({
        ...paymentRecordsFilters,
        fromDate: dateRange.startDate.toString(),
        toDate: dateRange.endDate.toString(),
        dateRange: Object.values(DateRangeOptions).includes(
          selectedDateRange as DateRangeOptions
        )
          ? selectedDateRange
          : undefined,
      });
    } else {
      setPaymentRecordsFilters({
        ...paymentRecordsFilters,
        dateRange: selectedDateRange,
      });
    }
  };

  const handleCustomDateChange = (
    startDate: Moment | null,
    endDate: Moment | null
  ) => {
    if (!startDate) {
      startDate = moment(paymentRecordsFilters.fromDate);
    }
    if (!endDate) {
      endDate = moment(paymentRecordsFilters.toDate);
    }
    if (startDate && endDate) {
      const startDateStartOfTheDay = startDate.startOf("day").toISOString();
      const endDateEndOfTheDay = endDate.endOf("day").toISOString();

      resetPage();
      setPaymentRecordsFilters({
        ...paymentRecordsFilters,
        fromDate: startDateStartOfTheDay,
        toDate: endDateEndOfTheDay,
      });
    }
  };

  const handleApplyFilters = () => {
    resetPage();
    const appliedFilters: PaymentRecordsFilters = { ...paymentRecordsFilters };
    filters.forEach((filter) => {
      const filterKeyword = filter.keyword as keyof PaymentRecordsFilters;
      const filterValue = filterValues[filter.keyword];
      if (filterKeyword === "orgId") {
        setSelectedOrgId(filterValue);
      }
      if (filterValue !== undefined && filterValue !== null) {
        appliedFilters[filterKeyword] = filterValue;
      } else {
        delete appliedFilters[filterKeyword];
      }
    });
    setPaymentRecordsFilters(appliedFilters);
    handleFilterClose();
  };

  const handleSearch = () => {
    setPaymentRecordsFilters({
      ...paymentRecordsFilters,
      search: searchTerm,
    });
  };

  useEffect(() => {
    if (user?.orgId) {
      setSelectedOrgIdFilter(user.orgId);
    }
  }, [user]);

  useEffect(() => {
    handleApplyFilters();
    return () => {
      const appliedFilters: PaymentRecordsFilters = {};
      filters.forEach((filter) => {
        const filterKeyword = filter.keyword as keyof PaymentRecordsFilters;
        const filterValue = filterValues[filter.keyword];
        if (filterKeyword === "orgId") {
          if (filterValue !== undefined && filterValue !== null) {
            appliedFilters[filterKeyword] = filterValue;
          } else {
            delete appliedFilters[filterKeyword];
          }
        }
      });
      setFilterValues({});
      setPaymentRecordsFilters({ ...paymentRecordsFilters, ...appliedFilters });
    };
  }, []);

  useEffect(() => {
    if (user?.roleCode === Role.Admin || user?.roleCode === Role.SuperAdmin) {
      setSelectedOrgIdFilter(filterValues["orgId"] || user?.orgId || 0);
    } else setSelectedOrgIdFilter(selectedOrgId);
  }, [filterValues["orgId"]]);

  useEffect(() => {
    if (fetchOrganizationsQuery.isSuccess) {
      const dropdown = OrganizationModel.buildOrganizationDropdown(
        fetchOrganizationsQuery.data
      );
      setOrganizationsDropdown(dropdown);
      const orgId = queryParams.get("orgId")
        ? Number(queryParams.get("orgId"))
        : fetchOrganizationsQuery.data[0].orgId;
      setSelectedOrgId(orgId);
      setSelectedOrgIdFilter(orgId);
      const filters = {
        ...filterValues,
        orgId: orgId,
      };
      setFilterValues(filters);
      setPaymentRecordsFilters({
        ...paymentRecordsFilters,
        orgId: orgId,
      });
    } else if (fetchOrganizationsQuery.isError) {
      setOrganizationsDropdown([]);
    }
  }, [
    fetchOrganizationsQuery.isSuccess,
    fetchOrganizationsQuery.isError,
    fetchOrganizationsQuery.data,
    fetchOrganizationsQuery.error,
    fetchOrganizationsQuery.isLoading,
    fetchOrganizationsQuery.isRefetching,
  ]);

  useEffect(() => {
    if (fetchAccountsQuery.isSuccess) {
      const dropdown = AccountModel.buildAccountsDropdown(
        fetchAccountsQuery.data
      );
      setAccountsDropdown(dropdown);
    } else if (fetchAccountsQuery.isError) {
      setAccountsDropdown([]);
    }
  }, [
    fetchAccountsQuery.isSuccess,
    fetchAccountsQuery.data,
    fetchAccountsQuery.isError,
    fetchAccountsQuery.isLoading,
    fetchAccountsQuery.isFetching,
    fetchAccountsQuery.isRefetching,
  ]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid2
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        p={1}
      >
        <Grid2
          pl={1}
          size={{ lg: 6, md: 12, sm: 12, xs: 12 }}
          container
          alignItems="flex-end"
          justifyContent={"flex-start"}
        >
          <Grid2 size={{ lg: 4.5, sm: 6, xs: 12 }}>
            <SelectBox
              label="Date"
              name="date"
              dropdown={dateRanges}
              value={selectedDateRange}
              onChange={(e) => handleDateChange(e.target.value)}
              sx={{ width: "100%", height: "33px" }}
            />
          </Grid2>
          {selectedDateRange === "customDate" && (
            <Grid2 size={{ lg: 12, sm: 12, xs: 12 }} alignSelf={"end"}>
              <DateRangePicker
                start={moment(paymentRecordsFilters.fromDate)}
                end={moment(paymentRecordsFilters.toDate)}
                onDateChange={(startDate, endDate) => {
                  handleCustomDateChange(startDate, endDate);
                }}
              />
            </Grid2>
          )}
        </Grid2>
        <Grid2
          pr={1}
          container
          alignItems="center"
          size={{ lg: 6, md: 12, xs: 12 }}
          justifyContent="flex-end"
        >
          <Grid2 size={{ xs: 12, sm: 4, lg: 3 }}>
            <Button
              label={"Refresh"}
              variantType={"secondary"}
              icon={AutorenewOutlinedIcon}
              onClick={onRefresh}
              sx={{
                width: "100%",
                height: "30px",
                padding: { xl: "1rem", lg: "0", xs: "0", sm: "0" },
              }}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 4, lg: 3 }}>
            <Button
              label={"Download"}
              variantType={"secondary"}
              icon={FileDownloadOutlinedIcon}
              sx={{
                width: "100%",
                height: "30px",
                padding: { xl: "1rem", lg: "0", xs: "0", sm: "0" },
              }}
              onClick={onDownload}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 4, lg: 3 }}>
            <Search
              handleSearch={handleSearch}
              onInputChange={(e) => setSearchTerm(e.target.value)}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 4, lg: 1 }} sx={{ flexBasis: "content" }}>
            <Box
              sx={{
                backgroundColor: theme.palette.primary.main,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "60px",
                height: "30px",
                width: "50px",
                cursor: "pointer",
              }}
              onClick={handleFilterClick}
            >
              <Badge
                badgeContent={Object.values(filterValues).length}
                color="secondary"
              >
                <MdOutlineFilterAlt
                  style={{ fontSize: "22px", color: "white" }}
                />
              </Badge>
            </Box>
          </Grid2>
        </Grid2>
      </Grid2>
      <Popover
        open={isFilterOpen}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          "& .MuiPopover-paper": {
            borderRadius: "8px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <>
          <Filter
            onApplyFilters={handleApplyFilters}
            onFilterChange={handleOnFilterChange}
            filters={filters}
            onClose={handleFilterClose}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            preservedFilters={["orgId", "paymentStatus"]}
          />
          {fetchAccountsQuery.isLoading && (
            <Loading
              message=" "
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 2,
                backgroundColor: "rgba(255, 255, 255, 0.6)",
              }}
            />
          )}
        </>
      </Popover>
    </Box>
  );
};

import { InvoiceStatus } from "../enums";
import { DropdownItem, InvoiceStatusTargetActions } from "../types";

export const ACCOUNT_DETAILS_TAB_KEY = "tab";
export const ORGANIZATION_DETAILS_TAB_KEY = "tab";
export const SESSION_STORAGE_USER_KEY = "user";
export const INTUIT_CONNECT_TO_QUICKBOOKS_CALLBACK_SOURCE =
  "intuitQuickbooksOAuthCallback";

export const DATE_RANGE_OPTIONS = [
  "Today",
  "Yesterday",
  "This Week",
  "Last Week",
  "This Month",
  "Last Month",
  "This Quarter",
  "Last Quarter",
  "Last 6 Months",
  "Last 12 Months",
  "This Year",
  "Custom Date",
];

export const WASH_FREQUENCIES: DropdownItem[] = [
  { key: "Daily", value: "daily" },
  { key: "Weekly", value: "weekly" },
  { key: "Monthly", value: "monthly" },
  { key: "Yearly", value: "yearly" },
  { key: "7 Days", value: "7days" },
  { key: "30 Days", value: "30days" },
];

export const INVOICE_FREQUENCIES: DropdownItem[] = [
  { key: "Daily", value: "daily" },
  { key: "Weekly", value: "weekly" },
  { key: "Biweekly", value: "biweekly" },
  { key: "Monthly", value: "monthly" },
];

export const DUE_UPON: DropdownItem[] = [
  { key: "Immediate Payment", value: "0" },
  { key: "Net 7", value: "7" },
  { key: "Net 14", value: "14" },
  { key: "Net 30", value: "30" },
  { key: "Net 60", value: "60" },
  { key: "Custom", value: "custom" },
];

export const DEFAULT_QUERY_OPTIONS: any = {
  retry: false,
};

export const INVOICE_STATUS_TARGET_ACTIONS_ADMIN: InvoiceStatusTargetActions = {
  [InvoiceStatus.Pending]: {
    defaultAction: InvoiceStatus.SendToQuickbooks,
    targetActions: [InvoiceStatus.SendToCustomer, InvoiceStatus.Cancelled],
  },
  [InvoiceStatus.InProgress]: {
    defaultAction: InvoiceStatus.PaymentReceived,
    targetActions: [
      InvoiceStatus.SendToCustomer,
      InvoiceStatus.OnHold,
      InvoiceStatus.Pending,
      InvoiceStatus.Cancelled,
    ],
  },
  [InvoiceStatus.SendToCustomer]: {
    defaultAction: InvoiceStatus.PaymentReceived,
    targetActions: [
      InvoiceStatus.OnHold,
      InvoiceStatus.Pending,
      InvoiceStatus.Cancelled,
    ],
  },
  [InvoiceStatus.OnHold]: {
    defaultAction: InvoiceStatus.PaymentReceived,
    targetActions: [
      InvoiceStatus.Pending,
      InvoiceStatus.SendToCustomer,
      InvoiceStatus.Cancelled,
    ],
  },
  [InvoiceStatus.All]: {
    defaultAction: InvoiceStatus.SendToCustomer,
    targetActions: [
      InvoiceStatus.PaymentReceived,
      InvoiceStatus.OnHold,
      InvoiceStatus.Pending,
      InvoiceStatus.InProgress,
      InvoiceStatus.Cancelled,
    ],
  },
  [InvoiceStatus.Cancelled]: {
    defaultAction: InvoiceStatus.Deleted,
  },
};

export const INVOICE_STATUS_TARGET_ACTIONS_CUSTOMER: InvoiceStatusTargetActions =
  {
    [InvoiceStatus.InProgress]: {
      defaultAction: InvoiceStatus.PaymentReceived,
    },
    [InvoiceStatus.SendToCustomer]: {
      defaultAction: InvoiceStatus.PaymentReceived,
    },
  };

export const CUSTOMER_INVOICE_STATUS_MAP: Record<string, InvoiceStatus[]> = {
  All: [
    InvoiceStatus.OnHold,
    InvoiceStatus.PaymentReceived,
    InvoiceStatus.InProgress,
    InvoiceStatus.SendToCustomer,
  ],
  Unpaid: [
    InvoiceStatus.OnHold,
    InvoiceStatus.InProgress,
    InvoiceStatus.SendToCustomer,
  ],
  Paid: [InvoiceStatus.PaymentReceived],
};

export const CUSTOMER_INVOICE_STATUS_FILTER_DROPDOWN: DropdownItem[] =
  Object.entries(CUSTOMER_INVOICE_STATUS_MAP).map(([key, values]) => ({
    key,
    value: values.join("|"),
  }));

export const CARD_LOGOS: { [key: string]: string } = {
  Visa: "/card-images/Visa.png",
  MasterCard: "/card-images/MasterCard.png",
  Amex: "/card-images/Amex.png",
  Discover: "/card-images/Discover.png",
  Diners: "/card-images/Diners.png",
  StoredValue: "/card-images/StoredValue.png",
  Other: "/card-images/Other.png",
  JCB: "/card-images/JCB.webp",
  CarteBlanche: "/card-images/CarteBlanche.png",
  Interac: "/card-images/Interac.png",
  UnionPay: "/card-images/UnionPay.png",
};

import { ChargeType } from "../enums";
import { DropdownItem, WashType } from "../types";

export class WashTypeModel {
  public static buildWashTypeDropDown(
    washTypes: WashType[] | undefined
  ): DropdownItem[] {
    if (!washTypes) {
      return [];
    }
    return washTypes
      .filter((washType) => washType.chargeType !== ChargeType.TripMinimum)
      .map((washType) => ({
        key: washType.name,
        value: washType.id,
      }));
  }

  public static buildWashType(
    washes: WashType[],
    orgId: number,
    miscellaneous: boolean
  ): WashType[] {
    return washes.map((wash) => ({
      ...wash,
      orgId: orgId,
      miscellaneous: miscellaneous,
    }));
  }
}

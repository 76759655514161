import { useQuery } from "react-query";
import { client } from "./axios";
import { config } from "../config";
import {
  FetchPaymentRecordsRequestParams,
  PaymentsInfo,
  QueryOptions,
} from "../types";
import { DEFAULT_QUERY_OPTIONS } from "../constants";
import { PaymentStatus } from "../enums";

const buildPaymentsUrl = (
  pathTemplate: string,
  paymentsRequestParams: FetchPaymentRecordsRequestParams
): string => {
  const { orgId, accountId, invoiceNumber, paymentStatus, filters } =
    paymentsRequestParams;

  let path = pathTemplate
    .replace("{orgId}", orgId.toString())
    .replace("{accountId}", accountId.toString())
    .replace("{invoiceNumber}", invoiceNumber.toString());

  if (paymentStatus === PaymentStatus.All) {
    path += `?status=${PaymentStatus.PaymentSuccess}&status=${PaymentStatus.PaymentFailed}`;
  } else {
    path += `?status=${paymentStatus}`;
  }

  const queryParams = new URLSearchParams(
    Object.entries(filters).reduce((params, [key, value]) => {
      if (value !== undefined && value !== null && value !== "") {
        params[key] = value.toString();
      }
      return params;
    }, {} as Record<string, string>)
  );

  if (queryParams.toString()) {
    path += `&${queryParams.toString()}`;
  }

  return path;
};

export const useFetchPaymentRecordsQuery = (
  paymentsRequestParams: FetchPaymentRecordsRequestParams,
  queryOptions?: QueryOptions<any[]>
) => {
  const getAllPaymentRecords = async (): Promise<PaymentsInfo> => {
    const path = buildPaymentsUrl(
      config.api.worldpayIntegrationService.getAllPaymentRecords,
      paymentsRequestParams
    );
    const response = await client.get<any>(path);
    return response.data.payments;
  };
  return useQuery(
    ["getAllPaymentRecords", paymentsRequestParams],
    getAllPaymentRecords,
    {
      ...queryOptions,
      ...DEFAULT_QUERY_OPTIONS,
    }
  );
};

import moment, { Moment } from "moment";
import { DateRange } from "../types";
import { DateRangeOptions } from "../enums";

export const formatDate = (
  date: string | moment.Moment | number,
  format: string = "YYYY-MM-DD"
): string => {
  return moment(date).format(format);
};

export const getDateRange = (range: DateRangeOptions | string): DateRange => {
  const startOfToday = moment().startOf("day");
  const endOfToday = moment().endOf("day");

  switch (range) {
    case "today":
      return {
        startDate: startOfToday.toISOString(),
        endDate: endOfToday.toISOString(),
      };
    case "yesterday":
      const yesterday = moment().subtract(1, "days");
      const yesterdayStart = yesterday.startOf("day").toISOString();
      const yesterdayEnd = yesterday.endOf("day").toISOString();

      return {
        startDate: yesterdayStart,
        endDate: yesterdayEnd,
      };
    case "thisWeek":
      const thisWeekStart = moment().startOf("isoWeek").toISOString();
      const thisWeekEnd = moment().endOf("isoWeek").toISOString();

      return {
        startDate: thisWeekStart,
        endDate: thisWeekEnd,
      };
    case "lastWeek":
      const lastWeek = moment().subtract(1, "weeks");
      const lastWeekStart = lastWeek.startOf("isoWeek").toISOString();
      const lastWeekEnd = lastWeek.endOf("isoWeek").toISOString();

      return {
        startDate: lastWeekStart,
        endDate: lastWeekEnd,
      };
    case "thisMonth":
      const thisMonthStart = moment().startOf("month").toISOString();
      const thisMonthEnd = moment().endOf("month").toISOString();

      return {
        startDate: thisMonthStart,
        endDate: thisMonthEnd,
      };
    case "lastMonth":
      const lastMonth = moment().subtract(1, "months");
      const lastMonthStart = lastMonth.startOf("month").toISOString();
      const lastMonthEnd = lastMonth.endOf("month").toISOString();

      return {
        startDate: lastMonthStart,
        endDate: lastMonthEnd,
      };
    case "thisQuarter":
      const thisQuarterStart = moment().startOf("quarter").toISOString();
      const thisQuarterEnd = moment().endOf("quarter").toISOString();

      return {
        startDate: thisQuarterStart,
        endDate: thisQuarterEnd,
      };
    case "lastQuarter":
      const lastQuarter = moment().subtract(1, "quarters");
      const lastQuarterStart = lastQuarter.startOf("quarter").toISOString();
      const lastQuarterEnd = lastQuarter.endOf("quarter").toISOString();

      return {
        startDate: lastQuarterStart,
        endDate: lastQuarterEnd,
      };
    case "last6Months":
      const last6Months = moment().subtract(6, "months");
      const last6MonthsStart = last6Months.startOf("day").toISOString();
      const last6MonthsEnd = endOfToday.toISOString();

      return {
        startDate: last6MonthsStart,
        endDate: last6MonthsEnd,
      };
    case "last12Months":
      const last12Months = moment().subtract(12, "months");
      const last12MonthsStart = last12Months.startOf("day").toISOString();
      const last12MonthsEnd = endOfToday.toISOString();

      return {
        startDate: last12MonthsStart,
        endDate: last12MonthsEnd,
      };
    case "yearToDate":
      const yearToDateStart = moment().startOf("year").toISOString();
      const yearToDateEnd = moment().endOf("year").toISOString();

      return {
        startDate: yearToDateStart,
        endDate: yearToDateEnd,
      };
    case "thisYear":
      const thisYearStart = moment().startOf("year").toISOString();
      const thisYearEnd = moment().endOf("year").toISOString();

      return {
        startDate: thisYearStart,
        endDate: thisYearEnd,
      };
    default:
      return {
        startDate: "",
        endDate: "",
      };
  }
};

export const getDate = (date: string): string => {
  const today = moment().startOf("day");

  switch (date) {
    case "today":
      return formatDate(today);
    case "yesterday":
      const yesterday = formatDate(moment().subtract(1, "days").startOf("day"));
      return formatDate(yesterday);
    default:
      return "";
  }
};

export const utcStringToMoment = (dateString: string): Moment => {
  return moment(dateString);
};

export const addDays = (date: string | moment.Moment, days: number) => {
  return moment(date).add(days, "d");
};

export const hasDatePassed = (date: string | moment.Moment) => {
  const inputDate = moment(date);
  const today = moment().startOf("day");

  return inputDate.isBefore(today);
};

export const isValidDate = (date: string): boolean => {
  const parsedDate = new Date(date);
  const currentDate = new Date();
  return !isNaN(parsedDate.getTime()) && parsedDate <= currentDate;
};

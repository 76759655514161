import { Box, Grid2 } from "@mui/material";
import { DataGrid, Toast } from "../common";
import {
  FetchPaymentRecordsRequestParams,
  ToastOptions,
  PaymentsInfo,
  PaymentRecordsFilters,
} from "../../types";
import { theme } from "../../utils";
import { useAtom } from "jotai";
import {
  paymentsDataGridPaginationAtom,
  paymentsFilterStateAtom,
  selectedAccountAtom,
  selectedOrgIdAtom,
  userAtom,
} from "../../atoms";
import { GridColDef, GridSortDirection, GridSortModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { formatDate, getDateRange } from "../../utils/dateUtil";
import { DateRangeOptions, PaymentStatus, Role } from "../../enums";
import { PaymentsToolBar } from "./fragments";
import { useSearchParams } from "react-router-dom";
import { useFetchPaymentRecordsQuery } from "../../api";

export const Payments = () => {
  const [user] = useAtom(userAtom);
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const [selectedAccount] = useAtom(selectedAccountAtom);
  const [filterValues, setFilterValues] = useAtom(paymentsFilterStateAtom);
  const [paymentRecords, setPaymentRecords] = useState<PaymentsInfo>();
  const [queryParams, setQueryParams] = useSearchParams();
  const [paymentsPaginationModel, setPaymentsPaginationModel] = useAtom(
    paymentsDataGridPaginationAtom
  );
  const [paymentRecordsFilters, setPaymentRecordsFilters] =
    useState<PaymentRecordsFilters>({
      fromDate: getDateRange("thisMonth").startDate,
      toDate: getDateRange("thisMonth").endDate,
      sortBy: "transactionDate",
      sortOrder: "desc",
      paymentStatus: PaymentStatus.All,
      search: "",
    });
  const [toastOptions, setToastOptions] = useState<ToastOptions>({
    open: false,
    message: "",
    severity: "info",
  });
  const [selectedDateRange, setSelectedDateRange] =
    useState<string>("thisMonth");

  const handleSortModelChange = (sortModel: GridSortModel) => {
    if (sortModel.length > 0) {
      const sortField = sortModel[0].field;
      const sortOrder: GridSortDirection = sortModel[0].sort;
      if (sortOrder) {
        setPaymentRecordsFilters({
          ...paymentRecordsFilters,
          sortBy: sortField,
          sortOrder: sortOrder,
        });
      }
    } else if (paymentRecordsFilters.sortBy !== "") {
      setPaymentRecordsFilters({
        ...paymentRecordsFilters,
        sortBy: "",
        sortOrder: "",
      });
    }
  };
  const buildPaymentsRequestParams = (): FetchPaymentRecordsRequestParams => {
    return {
      orgId: selectedOrgId,
      accountId:
        user?.roleCode === Role.SuperAdmin || user?.roleCode === Role.Admin
          ? paymentRecordsFilters?.accountId || 0
          : selectedAccount?.accountId || 0,
      paymentStatus: paymentRecordsFilters?.paymentStatus || PaymentStatus.All,
      invoiceNumber: paymentRecordsFilters.invoiceNumber || "0",
      filters: {
        page: paymentRecordsFilters?.page ? paymentRecordsFilters?.page + 1 : 1,
        pageSize: paymentRecordsFilters?.pageSize,
        fromDate: paymentRecordsFilters?.fromDate,
        toDate: paymentRecordsFilters?.toDate,
        sortBy: paymentRecordsFilters?.sortBy,
        sortOrder: paymentRecordsFilters?.sortOrder,
        search: paymentRecordsFilters?.search,
      },
    };
  };

  const fetchPaymentRecordsQuery = useFetchPaymentRecordsQuery(
    buildPaymentsRequestParams(),
    { enabled: selectedOrgId ? true : false }
  );

  const columns: GridColDef[] = [
    {
      field: "transactionDate",
      headerName: "Date",
      width: 210,
      editable: false,
      valueGetter: (value) => {
        return formatDate(value, "dddd - MMMM DD,YYYY");
      },
    },
    {
      field: "accountName",
      headerName: "Account Name",
      minWidth: 200,
      editable: false,
    },
    {
      field: "invoiceNumber",
      headerName: "Invoice Number",
      width: 140,
      editable: false,
    },
    {
      field: "paymentMethod",
      headerName: "Payment Method",
      width: 130,
      headerAlign: "center",
      align: "center",
      editable: false,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 80,
      headerAlign: "center",
      align: "center",
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 170,
      headerAlign: "center",
      align: "center",
      editable: false,
    },
    {
      field: "transactionId",
      headerName: "Transaction Id",
      width: 150,
      headerAlign: "center",
      align: "center",
      editable: false,
    },
  ];

  const handleRefresh = () => {
    fetchPaymentRecordsQuery.refetch();
  };
  const handleDownload = () => {
    console.log("download clicked");
  };

  const resetPage = () => {
    setPaymentsPaginationModel({
      ...paymentsPaginationModel,
      page: 0,
    });
  };

  useEffect(() => {
    if (fetchPaymentRecordsQuery.isSuccess) {
      setPaymentRecords(fetchPaymentRecordsQuery.data);
    }
  }, [fetchPaymentRecordsQuery.isSuccess, fetchPaymentRecordsQuery.data]);

  useEffect(() => {
    setPaymentRecordsFilters({
      ...paymentRecordsFilters,
      page: paymentsPaginationModel.page,
      pageSize: paymentsPaginationModel.pageSize,
    });
  }, [paymentsPaginationModel]);

  useEffect(() => {
    const dateRange = queryParams.get("dateRange");
    const dateFilters: Partial<PaymentRecordsFilters> = {
      fromDate: queryParams.get("fromDate") || undefined,
      toDate: queryParams.get("toDate") || undefined,
    };
    if (
      dateRange &&
      Object.values(DateRangeOptions).includes(dateRange as DateRangeOptions)
    ) {
      if (dateRange !== DateRangeOptions.CustomDate) {
        dateFilters.fromDate = getDateRange(dateRange).startDate;
        dateFilters.toDate = getDateRange(dateRange).endDate;
      }
      dateFilters.dateRange = dateRange;

      setSelectedDateRange(dateRange);
    }

    const queryFilters: PaymentRecordsFilters = {
      orgId: Number(queryParams.get("orgId")) || undefined,
      accountId: Number(queryParams.get("accountId")) || undefined,
      paymentStatus: queryParams.get("paymentStatus") || PaymentStatus.All,
      ...dateFilters,
      page: Number(queryParams.get("page")) || undefined,
      pageSize: Number(queryParams.get("pageSize")) || undefined,
      sortBy: queryParams.get("sortBy") || undefined,
      sortOrder: queryParams.get("sortOrder") || undefined,
    };

    const tempPaymentsFiltersFromQueryStrings = Object.entries(
      queryFilters
    ).reduce((acc: any, [key, value]) => {
      if (value) {
        acc[key] = value;
      }
      return acc;
    }, {});

    const tempFilterValuesFromQueryStrings = Object.entries({
      orgId: tempPaymentsFiltersFromQueryStrings.orgId,
      accountId: tempPaymentsFiltersFromQueryStrings.accountId,
      paymentStatus: tempPaymentsFiltersFromQueryStrings.paymentStatus,
    }).reduce((acc: any, [key, value]) => {
      if (value) {
        acc[key] = value;
      }
      return acc;
    }, {});

    Object.values(tempFilterValuesFromQueryStrings).length > 0 &&
      setFilterValues({
        ...filterValues,
        ...tempFilterValuesFromQueryStrings,
      });
    setPaymentRecordsFilters({
      ...paymentRecordsFilters,
      ...tempPaymentsFiltersFromQueryStrings,
    });
    setPaymentsPaginationModel({
      ...paymentsPaginationModel,
      page: tempPaymentsFiltersFromQueryStrings.page || 0,
      pageSize: tempPaymentsFiltersFromQueryStrings.pageSize || 10,
    });
  }, []);

  useEffect(() => {
    const filters = Object.entries(paymentRecordsFilters).reduce(
      (acc: any, [key, value]) => {
        if (value) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    setQueryParams({
      ...filters,
    });
  }, [paymentRecordsFilters]);

  return (
    <Box
      sx={{
        marginTop: "50px",
        width: "100%",
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
      }}
    >
      <Toast setOptions={setToastOptions} options={toastOptions} />
      <Box>
        <Grid2
          container
          direction={"column"}
          spacing={2}
          sx={{
            marginTop: "10px",
            marginBottom: "10px",
            backgroundColor: "#f5f5f5",
          }}
        >
          <PaymentsToolBar
            onRefresh={handleRefresh}
            onDownload={handleDownload}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            resetPage={resetPage}
            paymentRecordsFilters={paymentRecordsFilters}
            setPaymentRecordsFilters={setPaymentRecordsFilters}
          />
        </Grid2>
        <Grid2 container direction={"column"} spacing={2}>
          <DataGrid
            columns={columns}
            rows={paymentRecords?.items || []}
            loading={
              fetchPaymentRecordsQuery.isLoading ||
              fetchPaymentRecordsQuery.isRefetching
            }
            sortModel={[
              {
                field: paymentRecordsFilters.sortBy || "",
                sort:
                  (paymentRecordsFilters.sortOrder as GridSortDirection) ||
                  null,
              },
            ]}
            onSortModelChange={handleSortModelChange}
            paginationModel={paymentsPaginationModel}
            setPaginationModel={setPaymentsPaginationModel}
            totalRecords={paymentRecords?.totalItems}
            paginationMode="server"
            rowCount={
              paymentRecords?.totalItems ? paymentRecords?.totalItems : 0
            }
            pageSize={paymentRecords?.pageSize}
            sx={{
              border: "none",
              backgroundColor: theme.palette.secondary.light,
              padding: "20px",
              paddingTop: "0px",
              "& .MuiSvgIcon-root": {
                fontSize: 16,
              },
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
              height: "70vh",
            }}
          />
        </Grid2>
      </Box>
    </Box>
  );
};

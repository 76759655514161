export const ROUTE_HOME = "/";
export const ROUTE_LOGIN = "/login";
export const ROUTE_ERROR = "/*";

export const ROUTE_SADM_ACCOUNTS = "/accounts";
export const ROUTE_SADM_DASHBOARD = "/dashboard";
export const ROUTE_SADM_ACCOUNT_DETAILS = ":accountId";
export const ROUTE_SADM_ACCOUNT_INVOICE_DETAILS = "invoices/:invoiceNumber";
export const ROUTE_SADM_INVOICE_DETAILS = ":invoiceNumber";
export const ROUTE_SADM_INVOICES = "/invoices";
export const ROUTE_SADM_CREATE_INVOICE = "create-invoice";
export const ROUTE_SADM_CREATE_ORGANIZATION = "create";
export const ROUTE_SADM_ORGANIZATIONS = "/organizations";
export const ROUTE_SADM_ORGANIZATION_DETAILS = ":orgId";
export const ROUTE_SADM_PAYMENTS = "/payments";
export const ROUTE_SADM_REPORTS = "/reports";
export const ROUTE_SADM_REPORTS_ACCOUNT_ACTIVITY = "account-activity";
export const ROUTE_SADM_REPORTS_LEAD = "lead-report";
export const ROUTE_SADM_REPORTS_WASH_DETAILS = "wash-details";
export const ROUTE_SADM_USERS = "/users";
export const ROUTE_SADM_WASH_TYPES = "/wash-types";
export const ROUTE_SADM_WASHES = "/washes";

export const ROUTE_CUST_ACCOUNT_DETAILS = "/details";
export const ROUTE_CUST_DASHBOARD = "/dashboard";
export const ROUTE_CUST_FLEET = "/fleet";
export const ROUTE_CUST_INVOICE_DETAILS = ":invoiceNumber";
export const ROUTE_CUST_INVOICES = "/invoices";
export const ROUTE_CUST_PAYMENTS = "/payments";
export const ROUTE_CUST_DETAILS_INVOICES = "invoices";
export const ROUTE_CUST_WASHES = "/washes";

export const ROUTE_ADM_ACCOUNTS = "/accounts";
export const ROUTE_ADM_DASHBOARD = "/dashboard";
export const ROUTE_ADM_ACCOUNT_DETAILS = ":accountId";
export const ROUTE_ADM_ACCOUNT_INVOICE_DETAILS = "invoices/:invoiceNumber";
export const ROUTE_ADM_INVOICE_DETAILS = ":invoiceNumber";
export const ROUTE_ADM_INVOICES = "/invoices";
export const ROUTE_ADM_CREATE_INVOICE = "create-invoice";
export const ROUTE_ADM_ORGANIZATION_DETAILS = "/details";
export const ROUTE_ADM_REPORTS = "/reports";
export const ROUTE_ADM_REPORTS_ACCOUNT_ACTIVITY = "account-activity";
export const ROUTE_ADM_REPORTS_LEAD = "lead-report";
export const ROUTE_ADM_REPORTS_WASH_DETAILS = "wash-details";
export const ROUTE_ADM_USERS = "/users";
export const ROUTE_ADM_WASHES = "/washes";
export const ROUTE_ADM_PAYMENTS = "/payments";

export const ROUTE_CREATE_ACCOUNT = "create";
export const ROUTE_USER_PROFILE = "/profile";
export const ROUTE_FORGOT_PASSWORD = "/forgot-password";
export const ROUTE_RESET_PASSWORD = "/reset-password";
export const ROUTE_CHANGE_PASSWORD =
  "/organizations/:orgId/users/:userId/change-password";

export const ORG_ID_QUERY_PARAM_KEY = "orgId";
export const ACCOUNT_ID_QUERY_PARAM_KEY = "accountId";

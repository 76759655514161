import { useMutation, useQuery } from "react-query";
import { client } from "./axios";
import { config } from "../config";
import {
  AccountBasicInfo,
  ApiLocationFleet,
  AccountLocationCharge,
  QueryOptions,
  UpdateTripMinimumPayload,
  AccountLocation,
  GetAccountInfoOptions,
  AccountInfo,
  AccountContact,
  ApiBasePathParams,
  UpdateFleetPayload,
  DeleteFleetPayload,
  AccountProfileInfo,
  PickAndPartial,
  ChargeInformation,
  FinancialInfo,
  ApiError,
  AccountSummary,
  UnWashedFleet,
} from "../types";
import { buildQueryString } from "../utils";
import { DEFAULT_QUERY_OPTIONS } from "../constants";
import { AxiosError, AxiosResponse } from "axios";

export const useFetchAccountsQuery = (
  orgId: number,
  queryOptions?: QueryOptions<AccountBasicInfo[]>
) => {
  const fetchAccounts = async (): Promise<AccountBasicInfo[]> => {
    const path = config.api.accountService.getAllAccounts.replace(
      "{orgId}",
      orgId.toString()
    );
    const response = await client.get<any>(path);

    return response.data.accounts;
  };

  return useQuery(["fetchAccounts", orgId], fetchAccounts, {
    ...queryOptions,
    ...DEFAULT_QUERY_OPTIONS,
  });
};

export const useFetchAllAccountsSummaryQuery = (
  orgId: number,
  queryOptions?: QueryOptions<any[]>
) => {
  const fetchAllAccountsSummary = async (): Promise<AccountSummary[]> => {
    const path = config.api.accountService.getAllAccountsSummary.replace(
      "{orgId}",
      orgId.toString()
    );
    const response = await client.get<any>(path);

    return response.data.accountSummary;
  };

  return useQuery(["allAccountsSummary", orgId], fetchAllAccountsSummary, {
    ...queryOptions,
    ...DEFAULT_QUERY_OPTIONS,
  });
};

export const useFetchAccountInfoQuery = (
  orgId: number,
  accountId: number,
  queryStringOptions?: GetAccountInfoOptions,
  queryOptions?: QueryOptions<AccountInfo>
) => {
  const fetchAccountInfo = async (): Promise<AccountInfo> => {
    const path = config.api.accountService.getAccountInfo
      .replace("{orgId}", orgId.toString())
      .replace("{accountId}", accountId.toString())
      .concat(buildQueryString(queryStringOptions));

    const response = await client.get<{
      accountInfo: AccountInfo;
    }>(path);

    return response.data.accountInfo;
  };

  return useQuery(
    ["fetchAccountInfo", orgId, accountId, queryStringOptions],
    fetchAccountInfo,
    { ...queryOptions, ...DEFAULT_QUERY_OPTIONS }
  );
};

export const useFetchAccountFleetsQuery = (
  orgId: number,
  accountId: number,
  fetchOnMount: boolean = true
) => {
  const fetchAccountFleets = async (): Promise<ApiLocationFleet[]> => {
    const path = config.api.accountService.getAccountFleets
      .replace("{orgId}", orgId.toString())
      .replace("{accountId}", accountId.toString());
    const response = await client.get<any>(path);

    return response.data.accountFleets;
  };

  return useQuery(
    ["fetchAccountFleets", orgId, accountId],
    fetchAccountFleets,
    {
      retry: false,
      enabled: fetchOnMount,
    }
  );
};

export const useFetchUnwashedAccountFleetsQuery = (
  orgId: number,
  accountId: number,
  fetchOnMount: boolean = false
) => {
  const fetchUnwashedAccountFleets = async (): Promise<UnWashedFleet[]> => {
    const path = config.api.accountService.getUnWashedAccountFleets
      .replace("{orgId}", orgId.toString())
      .replace("{accountId}", accountId.toString());
    const response = await client.get<any>(path);

    return response.data.list;
  };

  return useQuery(
    ["fetchUnwashedAccountFleets", orgId, accountId],
    fetchUnwashedAccountFleets,
    {
      retry: false,
      enabled: fetchOnMount,
    }
  );
};

export const useFetchAccountWashMiscChargesQuery = (
  orgId: number,
  accountId: number,
  locationNumber = 0,
  queryOptions?: QueryOptions<AccountLocationCharge>
) => {
  const fetchAccountWashMiscCharges =
    async (): Promise<AccountLocationCharge> => {
      const path = config.api.accountService.getAccountWashMiscCharges
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString())
        .replace("{locationNumber}", locationNumber.toString());

      const response = await client.get<{
        accountWashMiscCharges: AccountLocationCharge[];
      }>(path);

      return response.data.accountWashMiscCharges[0];
    };

  return useQuery(
    ["fetchAccountWashMiscCharges", orgId, accountId, locationNumber],
    fetchAccountWashMiscCharges,
    {
      ...queryOptions,
      ...DEFAULT_QUERY_OPTIONS,
    }
  );
};

export const useFetchAccountLocationsQuery = (
  orgId: number,
  accountId: number,
  queryOptions?: QueryOptions<AccountLocation[]>
) => {
  const fetchAccountLocations = async (): Promise<AccountLocation[]> => {
    const path = config.api.accountService.getAccountLocations
      .replace("{orgId}", orgId.toString())
      .replace("{accountId}", accountId.toString());

    const response = await client.get<{
      accountLocations: AccountLocation[];
    }>(path);

    return response.data.accountLocations;
  };

  return useQuery(
    ["fetchAccountLocations", orgId, accountId],
    fetchAccountLocations,
    {
      ...queryOptions,
      ...DEFAULT_QUERY_OPTIONS,
    }
  );
};

export const useUpdateTripMinimumMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError<ApiError>,
    ApiBasePathParams & UpdateTripMinimumPayload,
    unknown
  >(async (data: ApiBasePathParams & UpdateTripMinimumPayload) => {
    const { orgId, accountId, locationNumber, rate } = data;
    const path = config.api.accountService.updateTripMinimum
      .replace("{orgId}", orgId.toString())
      .replace("{accountId}", accountId.toString())
      .replace("{locationNumber}", locationNumber.toString());

    const response = await client.patch(path, { rate });
    return response;
  });

  return mutation;
};

export const useCreateContactMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError<ApiError>,
    ApiBasePathParams & { contact: AccountContact; override?: boolean },
    unknown
  >(
    async (
      data: ApiBasePathParams & { contact: AccountContact; override?: boolean }
    ) => {
      const { orgId, accountId, contact, override } = data;
      let path = config.api.accountService.createAccountContact
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString());
      if (override) {
        path += "?override=true";
      }
      const response = await client.post(path, { ...contact });

      return response;
    }
  );

  return mutation;
};

export const useCreateLocationMutation = () => {
  const mutation = useMutation(
    async (
      data: ApiBasePathParams & {
        location: Omit<AccountLocation, "locationNumber">;
      }
    ) => {
      const { orgId, accountId, location } = data;
      const path = config.api.accountService.createAccountLocation
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString());

      const response = await client.post(path, { ...location });

      return response;
    }
  );

  return mutation;
};

export const useUpdateFleetMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError<ApiError>,
    ApiBasePathParams & UpdateFleetPayload,
    unknown
  >(async (data: ApiBasePathParams & UpdateFleetPayload) => {
    const { orgId, accountId, accountFleetId, ...fleet } = data;
    const path = config.api.accountService.updateAccountFleet
      .replace("{orgId}", orgId.toString())
      .replace("{accountId}", accountId.toString())
      .replace("{accountFleetId}", accountFleetId?.toString());

    const response = await client.put(path, fleet);
    return response;
  });

  return mutation;
};

export const useBulkUpdateFleetMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError<ApiError>,
    ApiBasePathParams & { locationNumber: number },
    unknown
  >(async (data: ApiBasePathParams & { locationNumber: number }) => {
    const { orgId, accountId, locationNumber, ...fleet } = data;
    const path = config.api.accountService.bulkUpdateAccountFleet
      .replace("{orgId}", orgId.toString())
      .replace("{accountId}", accountId.toString())
      .replace("{locationNumber}", locationNumber.toString());

    const response = await client.put(path, fleet);
    return response;
  });

  return mutation;
};

export const useAddFleetMutation = () => {
  const mutation = useMutation(
    async (data: ApiBasePathParams & UpdateFleetPayload) => {
      const { orgId, accountId, ...fleet } = data;
      const path = config.api.accountService.createAccountFleet
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString());
      const response = await client.post(path, fleet);
      return response;
    }
  );

  return mutation;
};

export const useDeleteFleetMutation = () => {
  const mutation = useMutation(
    async (data: ApiBasePathParams & DeleteFleetPayload) => {
      const { orgId, accountId, ...fleetIds } = data;
      const path = config.api.accountService.deleteAccountFleet
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString());
      const response = await client.delete(path, {
        data: fleetIds,
      });
      return response;
    }
  );
  return mutation;
};

export const useUpdateProfileAndAddressMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError<ApiError>,
    ApiBasePathParams & Partial<AccountProfileInfo>,
    unknown
  >(async (data: ApiBasePathParams & Partial<AccountProfileInfo>) => {
    const { orgId, accountId, ...profileInfo } = data;
    const path = config.api.accountService.updateProfileAndAddress
      .replace("{orgId}", orgId.toString())
      .replace("{accountId}", accountId.toString());

    const response = await client.patch(path, { ...profileInfo });
    return response;
  });

  return mutation;
};

export const useFetchAccountLogoUploadPreSignedUrlQuery = (
  orgId: number,
  accountId: number,
  fileName: string,
  queryOptions?: QueryOptions<string>
) => {
  const fetchPreSignedUrl = async (): Promise<string> => {
    const path = config.api.accountService.accountLogoUploadSignedUrl
      .replace("{orgId}", orgId.toString())
      .replace("{accountId}", accountId.toString())
      .replace("{fileName}", fileName.toString());

    const response = await client.get<{ url: string; key: string }>(path);

    return response.data.url;
  };

  return useQuery(
    ["accountFetchPreSignedUrl", orgId, accountId, fileName],
    fetchPreSignedUrl,
    {
      ...queryOptions,
      ...DEFAULT_QUERY_OPTIONS,
    }
  );
};

export const useUpdateContactMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError<ApiError>,
    ApiBasePathParams &
      PickAndPartial<AccountContact, "id"> & { override?: boolean },
    unknown
  >(
    async (
      data: ApiBasePathParams &
        PickAndPartial<AccountContact, "id"> & { override?: boolean }
    ) => {
      const { orgId, accountId, id, override, ...contact } = data;
      const queryParams = override ? `?override=${override}` : "";
      const path = `${config.api.accountService.updateContact
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString())
        .replace("{contactId}", id.toString())}${queryParams}`;
      const response = await client.patch(path, { ...contact });
      return response;
    }
  );

  return mutation;
};

export const useDeleteContactMutation = () => {
  const mutation = useMutation(
    async (data: ApiBasePathParams & { id: number }) => {
      const { orgId, accountId, id } = data;
      const path = config.api.accountService.deleteContact
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString())
        .replace("{contactId}", id.toString());

      const response = await client.delete(path);
      return response;
    }
  );
  return mutation;
};

export const useUpdateLocationMutation = () => {
  const mutation = useMutation(
    async (
      data: ApiBasePathParams & {
        locationNumber: number;
        location: Omit<AccountLocation, "locationNumber">;
      }
    ) => {
      const { orgId, accountId, location, locationNumber } = data;
      const path = config.api.accountService.updateAccountLocation
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString())
        .replace("{locationNumber}", locationNumber.toString());

      const response = await client.put(path, { ...location });

      return response;
    }
  );

  return mutation;
};

export const useDeleteLocationMutation = () => {
  const mutation = useMutation(
    async (data: ApiBasePathParams & { locationNumber: number }) => {
      const { orgId, accountId, locationNumber } = data;
      const path = config.api.accountService.deleteAccountLocation
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString())
        .replace("{locationNumber}", locationNumber.toString());

      const response = await client.delete(path);
      return response;
    }
  );

  return mutation;
};

export const useUpdateWashChargeMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError<ApiError>,
    ApiBasePathParams & {
      locationNumber: number;
      washTypeId: number;
      washCharge: Pick<
        ChargeInformation,
        "washTypeId" | "specialRate" | "washFrequency" | "instructions"
      >;
    },
    unknown
  >(
    async (
      data: ApiBasePathParams & {
        locationNumber: number;
        washTypeId: number;
        washCharge: Pick<
          ChargeInformation,
          "washTypeId" | "specialRate" | "washFrequency" | "instructions"
        >;
      }
    ) => {
      const { orgId, accountId, locationNumber, washTypeId, washCharge } = data;
      const path = config.api.accountService.updateAccountWashCharge
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString())
        .replace("{locationNumber}", locationNumber.toString())
        .replace("{washTypeId}", washTypeId.toString());

      const response = await client.put(path, { ...washCharge });

      return response;
    }
  );

  return mutation;
};

export const useUpdateMiscChargeMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError<ApiError>,
    ApiBasePathParams & {
      locationNumber: number;
      washTypeId: number;
      washCharge: Pick<ChargeInformation, "washTypeId" | "specialRate">;
    },
    unknown
  >(
    async (
      data: ApiBasePathParams & {
        locationNumber: number;
        washTypeId: number;
        washCharge: Pick<ChargeInformation, "washTypeId" | "specialRate">;
      }
    ) => {
      const { orgId, accountId, locationNumber, washTypeId, washCharge } = data;
      const path = config.api.accountService.updateAccountMiscCharge
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString())
        .replace("{locationNumber}", locationNumber.toString())
        .replace("{washTypeId}", washTypeId.toString());

      const response = await client.put(path, { ...washCharge });

      return response;
    }
  );

  return mutation;
};

export const useDeleteWashChargeMutation = () => {
  const mutation = useMutation(
    async (
      data: ApiBasePathParams & {
        locationNumber: number;
        washTypeId: number;
      }
    ) => {
      const { orgId, accountId, locationNumber, washTypeId } = data;
      const path = config.api.accountService.deleteAccountWashCharge
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString())
        .replace("{locationNumber}", locationNumber.toString())
        .replace("{washTypeId}", washTypeId.toString());

      const response = await client.delete(path);

      return response;
    }
  );

  return mutation;
};

export const useDeleteMiscChargeMutation = () => {
  const mutation = useMutation(
    async (
      data: ApiBasePathParams & {
        locationNumber: number;
        washTypeId: number;
      }
    ) => {
      const { orgId, accountId, locationNumber, washTypeId } = data;
      const path = config.api.accountService.deleteAccountMiscCharge
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString())
        .replace("{locationNumber}", locationNumber.toString())
        .replace("{washTypeId}", washTypeId.toString());

      const response = await client.delete(path);

      return response;
    }
  );

  return mutation;
};

export const useCreateWashChargeMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError<ApiError>,
    ApiBasePathParams & {
      locationNumber: number;
      charge: Pick<
        ChargeInformation,
        "washTypeId" | "specialRate" | "washFrequency" | "instructions"
      >;
    },
    unknown
  >(
    async (
      data: ApiBasePathParams & {
        locationNumber: number;
        charge: Pick<
          ChargeInformation,
          "washTypeId" | "specialRate" | "washFrequency" | "instructions"
        >;
      }
    ) => {
      const { orgId, accountId, locationNumber, charge } = data;
      const path = config.api.accountService.createWashTypeSplInstruction
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString())
        .replace("{locationNumber}", locationNumber.toString());

      const response = await client.post(path, { ...charge });

      return response;
    }
  );

  return mutation;
};

export const useCreateMiscChargeMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError<ApiError>,
    ApiBasePathParams & {
      locationNumber: number;
      charge: Pick<ChargeInformation, "washTypeId" | "specialRate">;
    },
    unknown
  >(
    async (
      data: ApiBasePathParams & {
        locationNumber: number;
        charge: Pick<ChargeInformation, "washTypeId" | "specialRate">;
      }
    ) => {
      const { orgId, accountId, locationNumber, charge } = data;
      const path = config.api.accountService.createAccountMiscCharge
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString())
        .replace("{locationNumber}", locationNumber.toString());

      const response = await client.post(path, { ...charge });

      return response;
    }
  );

  return mutation;
};

export const useBulkDeleteWashChargesMutation = () => {
  const mutation = useMutation(
    async (
      data: ApiBasePathParams & {
        locationNumber: number;
        washTypeIds: number[];
      }
    ) => {
      const { orgId, accountId, locationNumber, washTypeIds } = data;
      const path = config.api.accountService.bulkDeleteAccountWashCharges
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString())
        .replace("{locationNumber}", locationNumber.toString());

      const response = await client.delete(path, {
        data: { washTypeIds },
      });

      return response;
    }
  );
  return mutation;
};

export const useBulkDeleteMiscChargesMutation = () => {
  const mutation = useMutation(
    async (
      data: ApiBasePathParams & {
        locationNumber: number;
        washTypeIds: number[];
      }
    ) => {
      const { orgId, accountId, locationNumber, washTypeIds } = data;
      const path = config.api.accountService.bulkDeleteAccountMiscCharges
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString())
        .replace("{locationNumber}", locationNumber.toString());

      const response = await client.delete(path, {
        data: { washTypeIds },
      });

      return response;
    }
  );
  return mutation;
};

export const useUpdateAccountFinancialMutation = () => {
  const mutation = useMutation(
    async (
      data: ApiBasePathParams & { financialInfo: Partial<FinancialInfo> }
    ) => {
      const { orgId, accountId, financialInfo } = data;
      const path = config.api.accountService.updateAccountFinancial
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString());

      const response = await client.patch(path, { ...financialInfo });
      return response;
    }
  );

  return mutation;
};

export const useCreateAccountMutation = () => {
  const mutation = useMutation<
    number,
    AxiosError<ApiError>,
    Pick<ApiBasePathParams, "orgId"> & {
      accountInfo: {
        accountName: string;
        logo?: string;
      };
    },
    unknown
  >(
    async (
      data: Pick<ApiBasePathParams, "orgId"> & {
        accountInfo: {
          accountName: string;
          logo?: string;
        };
      }
    ) => {
      const { orgId, accountInfo } = data;
      const path = config.api.accountService.createAccount.replace(
        "{orgId}",
        orgId.toString()
      );

      const response = await client.post(path, { ...accountInfo });
      return response.data.accountId;
    }
  );

  return mutation;
};

export const useCreateAccountFinancialMutation = () => {
  const mutation = useMutation(
    async (
      data: ApiBasePathParams & {
        financialInfo: Pick<
          FinancialInfo,
          "purchaseOrder" | "invoiceFrequency" | "quickbooksId" | "invoiceDate"
        >;
      }
    ) => {
      const { orgId, accountId, financialInfo } = data;
      const path = config.api.accountService.createAccountFinancial
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString());

      const response = await client.post(path, { ...financialInfo });
      return response.data;
    }
  );

  return mutation;
};

export const useDeleteAccountMutation = () => {
  const mutation = useMutation(async (data: ApiBasePathParams) => {
    const { orgId, accountId } = data;
    const path = config.api.accountService.deleteAccount
      .replace("{orgId}", orgId.toString())
      .replace("{accountId}", accountId.toString());

    const response = await client.delete(path);
    return response;
  });
  return mutation;
};

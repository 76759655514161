import { Box, Grid2, Typography } from "@mui/material";
import { DataGrid } from "../common";
import { WashesRequestParams, WashItem } from "../../types";
import { formatDate, getDateRange, theme } from "../../utils";
import { useAtom } from "jotai";
import {
  createInvoiceDataGridPaginationAtom,
  selectedAccountAtom,
  selectedOrgIdAtom,
  toastOptionsAtom,
  userAtom,
} from "../../atoms";
import { GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { CreateInvoiceToolbar } from "./fragments";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import { useFetchAllUnbilledWashInventoryQuery } from "../../api/washInventoryService";
import { Role, StatusCodes } from "../../enums";
import { useCreateOnDemandInvoiceMutation } from "../../api/invoiceService";
import { CreateRegenerateInvoiceModal } from "../common/modal/CreateRegenerateInvoiceModal";
import moment from "moment";

type CreateInvoiceProps = { handleRefresh?: () => void };

export const CreateInvoice: React.FC<CreateInvoiceProps> = ({
  handleRefresh,
}) => {
  const [user] = useAtom(userAtom);
  const [selectedOrgId, setSelectedOrgId] = useAtom(selectedOrgIdAtom);
  const [selectedAccount] = useAtom(selectedAccountAtom);
  const currentDate = getDateRange("thisMonth");
  const [createInvoiceFilters, setCreateInvoiceFilters] = useState({
    fromDate: currentDate.startDate,
    toDate: currentDate.endDate,
    orgId: 0,
    accountId: 0,
  });
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [unbilledWashInventories, setUnbilledWashInventories] = useState<
    WashItem[]
  >([]);
  const [selectedUnbilledWashInventories, setSelectedUnbilledWashInventories] =
    useState<number[]>([]);
  const [createInvoicePaginationModel, setCreateInvoicePaginationModel] =
    useAtom(createInvoiceDataGridPaginationAtom);
  const [, setToastOptions] = useAtom(toastOptionsAtom);

  const buildUnbilledWashInventoryRequestParams = (): WashesRequestParams => {
    return {
      orgId: selectedOrgId,
      accountId:
        user?.roleCode === Role.SuperAdmin || user?.roleCode === Role.Admin
          ? createInvoiceFilters?.accountId || 0
          : selectedAccount?.accountId || 0,
      filters: {
        fromDate: moment(createInvoiceFilters?.fromDate)
          .startOf("day")
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
        toDate: moment(createInvoiceFilters?.toDate)
          .endOf("day")
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
      },
    };
  };

  const fetchAllUnbilledWashInventoryQuery =
    useFetchAllUnbilledWashInventoryQuery(
      buildUnbilledWashInventoryRequestParams(),
      selectedOrgId ? (createInvoiceFilters.accountId ? true : false) : false
    );
  const createOnDemandInvoiceMutation = useCreateOnDemandInvoiceMutation();

  const handleRowSelectionChange = (selectedRowIds: number[]) => {
    setSelectedUnbilledWashInventories(selectedRowIds);
  };

  const handleCreateInvoice = () => {
    selectedOrgId &&
      createInvoiceFilters.accountId &&
      createInvoiceFilters.fromDate &&
      createInvoiceFilters.toDate &&
      createOnDemandInvoiceMutation.mutate({
        orgId: selectedOrgId,
        accountId: createInvoiceFilters.accountId,
        washInventoryIds: selectedUnbilledWashInventories,
        fromDate: createInvoiceFilters.fromDate,
        toDate: createInvoiceFilters.toDate,
      });
  };

  const columns: GridColDef[] = [
    {
      field: "endTime",
      headerName: "Date",
      width: 230,
      editable: false,
      valueGetter: (value) => {
        return formatDate(value, "dddd - MMMM DD,YYYY");
      },
    },
    {
      field: "leadName",
      headerName: "Lead Name",
      width: 200,
      editable: false,
      sortable: false,
    },
    {
      field: "washTypeName",
      headerName: "Wash Type",
      width: 150,
      editable: false,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 150,
      headerAlign: "center",
      align: "center",
      editable: false,
    },
    {
      field: "vehicleNumber",
      headerName: "Fleet Number",
      width: 150,
      editable: false,
    },
  ];

  useEffect(() => {
    if (fetchAllUnbilledWashInventoryQuery.isSuccess) {
      setUnbilledWashInventories(fetchAllUnbilledWashInventoryQuery.data);
    }

    if (fetchAllUnbilledWashInventoryQuery.isError) {
      const error = fetchAllUnbilledWashInventoryQuery.error as {
        status: number;
      };
      if (error.status !== StatusCodes.NotFound) {
        setToastOptions({
          open: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
      setUnbilledWashInventories([]);
    }
  }, [
    fetchAllUnbilledWashInventoryQuery.isSuccess,
    fetchAllUnbilledWashInventoryQuery.data,
    fetchAllUnbilledWashInventoryQuery.isError,
    fetchAllUnbilledWashInventoryQuery.isLoading,
    fetchAllUnbilledWashInventoryQuery.isFetching,
    fetchAllUnbilledWashInventoryQuery.isFetched,
    fetchAllUnbilledWashInventoryQuery.isRefetching,
  ]);

  useEffect(() => {
    if (createOnDemandInvoiceMutation.isSuccess) {
      setUnbilledWashInventories(
        unbilledWashInventories.filter(
          (item) => !selectedUnbilledWashInventories.includes(item.id)
        )
      );
      setSelectedUnbilledWashInventories([]);
      setRequestId(createOnDemandInvoiceMutation.data.data);
      setIsStatusModalOpen(true);
    }

    if (createOnDemandInvoiceMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    createOnDemandInvoiceMutation.isSuccess,
    createOnDemandInvoiceMutation.data,
    createOnDemandInvoiceMutation.isError,
    createOnDemandInvoiceMutation.isLoading,
  ]);

  useEffect(() => {
    setSelectedOrgId(
      selectedOrgId !== 0 ? selectedOrgId : user?.orgId ? user?.orgId : 0
    );
    if (createInvoiceFilters.accountId) {
      fetchAllUnbilledWashInventoryQuery.refetch();
    }
  }, [createInvoiceFilters]);

  return (
    <Box
      sx={{
        marginTop: "50px",
        width: "100%",
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
      }}
    >
      <Box sx={{ width: "100%", pt: 2, px: 3.5 }}>
        <Typography>Washes</Typography>
      </Box>
      <Box>
        <Grid2
          container
          direction={"column"}
          spacing={2}
          sx={{
            marginTop: "10px",
            marginBottom: "10px",
            backgroundColor: "#f5f5f5",
          }}
        >
          <CreateInvoiceToolbar
            createInvoiceFilters={createInvoiceFilters}
            setCreateInvoiceFilters={setCreateInvoiceFilters}
          />
        </Grid2>
        <Grid2 container direction={"column"} spacing={2}>
          <DataGrid
            columns={columns}
            rows={unbilledWashInventories || []}
            loading={
              fetchAllUnbilledWashInventoryQuery.isLoading ||
              fetchAllUnbilledWashInventoryQuery.isRefetching ||
              createOnDemandInvoiceMutation.isLoading
            }
            paginationModel={createInvoicePaginationModel}
            setPaginationModel={setCreateInvoicePaginationModel}
            onSelectedRowIdsChange={handleRowSelectionChange}
            sx={{
              border: "none",
              backgroundColor: theme.palette.secondary.light,
              padding: "20px",
              paddingTop: "0px",
              "& .MuiSvgIcon-root": {
                fontSize: 16,
              },
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
              height: { lg: "55vh", md: "40vh", xs: "50vh", sm: "45vh" },
            }}
            footerProps={{
              addBtnLabel: "Create Invoice",
              hasAddIcon: true,
              customAddIcon: ReceiptLongOutlinedIcon,
              onAddButtonClick: handleCreateInvoice,
              disableAddBtn:
                selectedUnbilledWashInventories.length === 0 ||
                createOnDemandInvoiceMutation.isLoading,
            }}
          />
        </Grid2>
      </Box>
      <CreateRegenerateInvoiceModal
        open={isStatusModalOpen}
        onClose={() => setIsStatusModalOpen(false)}
        title={"Create Invoice"}
        requestId={requestId}
        orgId={
          createInvoiceFilters.orgId
            ? createInvoiceFilters.orgId
            : user?.orgId || 0
        }
        accountId={createInvoiceFilters.accountId}
        handleRefresh={handleRefresh}
      />
    </Box>
  );
};

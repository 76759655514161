import {
  Box,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { DateRangePicker } from "../../common";
import { DropdownItem } from "../../../types";
import {
  selectedAccountIdAtom,
  selectedOrgIdAtom,
  userAtom,
} from "../../../atoms";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { Moment } from "moment";
import { AllowedAccess } from "@zdistancelab-packages/ui-permission-validator";
import { Action, Module, Role } from "../../../enums";
import {
  useFetchAccountsQuery,
  useFetchOrganizationsQuery,
} from "../../../api";
import { AccountModel, OrganizationModel } from "../../../models";
import moment from "moment";

type CreateInvoiceToolbarProps = {
  createInvoiceFilters: {
    fromDate: string;
    toDate: string;
    orgId: number;
    accountId: number;
  };
  setCreateInvoiceFilters: React.Dispatch<
    React.SetStateAction<{
      fromDate: string;
      toDate: string;
      orgId: number;
      accountId: number;
    }>
  >;
};

export const CreateInvoiceToolbar = ({
  createInvoiceFilters,
  setCreateInvoiceFilters,
}: CreateInvoiceToolbarProps) => {
  const [user] = useAtom(userAtom);
  const [selectedOrgId, setSelectedOrgId] = useAtom(selectedOrgIdAtom);
  const [selectedAccountId] = useAtom(
    selectedAccountIdAtom
  );

  const [selectedAccountIdFilter, setSelectedAccountIdFilter] = useState(
    createInvoiceFilters.accountId || 0
  );
  const [selectedOrgIdFilter, setSelectedOrgIdFilter] = useState(
    createInvoiceFilters.orgId || user?.orgId || 0
  );

  const [organizationsDropdown, setOrganizationsDropdown] = useState<
    DropdownItem[]
  >([]);
  const [accountsDropdown, setAccountsDropdown] = useState<DropdownItem[]>([]);

  const fetchAccountsQuery = useFetchAccountsQuery(
    selectedOrgIdFilter || user?.orgId || 0
  );
  const fetchOrganizationsQuery = useFetchOrganizationsQuery({
    enabled: user?.roleCode === Role.SuperAdmin,
  });

  const handleCustomDateChange = (
    startDate: Moment | null,
    endDate: Moment | null
  ) => {
    if (!startDate) {
      startDate = moment().startOf("month");
    }
    if (!endDate) {
      endDate = moment().endOf("day");
    }
    if (startDate && endDate) {
      const startDateStartOfTheDay = startDate.startOf("day").toISOString();
      const endDateEndOfTheDay = endDate.endOf("day").toISOString();

      setCreateInvoiceFilters({
        ...createInvoiceFilters,
        fromDate: startDateStartOfTheDay,
        toDate: endDateEndOfTheDay,
      });
    }
  };

  const handleChangeOrganizationsDropDown = (event: SelectChangeEvent) => {
    const orgId = Number(event.target.value);
    setSelectedOrgId(orgId);
    setSelectedOrgIdFilter(orgId);
    setCreateInvoiceFilters((prev) => ({ ...prev, orgId }));
  };

  const handleChangeAccountsDropDown = (event: SelectChangeEvent) => {
    const accountId = Number(event.target.value);
    setSelectedAccountIdFilter(accountId);
    setCreateInvoiceFilters((prev) => ({ ...prev, accountId }));
  };

  useEffect(() => {
    if (fetchOrganizationsQuery.isSuccess) {
      const dropdown = OrganizationModel.buildOrganizationDropdown(
        fetchOrganizationsQuery.data
      );
      setOrganizationsDropdown(dropdown);

      if (!createInvoiceFilters.orgId) {
        setCreateInvoiceFilters((prev) => ({
          ...prev,
          orgId: selectedOrgId,
        }));
      }
    } else if (fetchOrganizationsQuery.isError) {
      setOrganizationsDropdown([]);
    }
  }, [
    fetchOrganizationsQuery.isSuccess,
    fetchOrganizationsQuery.isError,
    fetchOrganizationsQuery.data,
    fetchOrganizationsQuery.error,
    fetchOrganizationsQuery.isLoading,
    fetchOrganizationsQuery.isRefetching,
  ]);

  useEffect(() => {
    if (fetchAccountsQuery.isSuccess) {
      const dropdown = AccountModel.buildAccountsDropdown(
        fetchAccountsQuery.data
      );
      setAccountsDropdown(dropdown);

      if (!createInvoiceFilters.accountId && selectedAccountId) {
        setSelectedAccountIdFilter(selectedAccountId);
        setCreateInvoiceFilters((prev) => ({
          ...prev,
          accountId: selectedAccountId,
        }));
      }
    } else if (fetchAccountsQuery.isError) {
      setAccountsDropdown([]);
    }
  }, [
    fetchAccountsQuery.isSuccess,
    fetchAccountsQuery.data,
    fetchAccountsQuery.isError,
    fetchAccountsQuery.error,
    fetchAccountsQuery.isRefetching,
  ]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid2
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ marginTop: "20px", flexWrap: "nowrap", display: "block" }}
      >
        <Grid2
          container
          alignItems="center"
          sx={{ marginLeft: "20px", marginRight: "20px" }}
        >
          <Grid2 size={{ lg: 6, sm: 12, xs: 6 }}>
            <DateRangePicker
              start={moment(createInvoiceFilters.fromDate)}
              end={moment(createInvoiceFilters.toDate)}
              onDateChange={handleCustomDateChange}
            />
          </Grid2>
          <AllowedAccess
            roles={[Role.SuperAdmin]}
            permissions={[{ module: Module.Organization, action: Action.Read }]}
          >
            <Grid2 size={{ lg: 3, sm: 12, xs: 6 }}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel>Organization</InputLabel>
                <Select
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "25px",
                    width: "100%",
                  }}
                  value={selectedOrgIdFilter.toString()}
                  onChange={handleChangeOrganizationsDropDown}
                >
                  {organizationsDropdown.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid2>
          </AllowedAccess>
          <Grid2 size={{ lg: 3, sm: 12, xs: 6 }}>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel>Account</InputLabel>
              <Select
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "25px",
                  width: "100%",
                }}
                value={selectedAccountIdFilter.toString()}
                onChange={handleChangeAccountsDropDown}
              >
                {accountsDropdown.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid2>
        </Grid2>
      </Grid2>
    </Box>
  );
};

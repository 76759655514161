import { useMutation, useQuery } from "react-query";
import { client } from "./axios";
import { config } from "../config";
import {
  ApiBasePathParams,
  ApiError,
  OmnitokenInfo,
  QueryOptions,
} from "../types";
import { AxiosError } from "axios";
import { DEFAULT_QUERY_OPTIONS } from "../constants";

export const useFetchHostedPaymentsPageMutation = () => {
  const mutation = useMutation<
    string,
    AxiosError<ApiError>,
    ApiBasePathParams & {
      invoiceNumber: string;
      amount: number;
      addressLine1: string;
      addressLine2: string;
      city: string;
      state: string;
      zip: string;
      saveCard?: boolean;
    },
    unknown
  >(
    async (
      data: ApiBasePathParams & {
        invoiceNumber: string;
        amount: number;
        addressLine1: string;
        addressLine2: string;
        city: string;
        state: string;
        zip: string;
        saveCard?: boolean;
      }
    ) => {
      const { orgId, accountId, invoiceNumber, ...payload } = data;

      const path =
        config.api.worldpayIntegrationService.initiateWorldpayTransaction
          .replace("{orgId}", orgId.toString())
          .replace("{accountId}", accountId.toString())
          .replace("{invoiceNumber}", invoiceNumber.toString());

      const response = await client.post(path, { ...payload });

      return response.data.hostedPaymentsPageUrl;
    }
  );

  return mutation;
};

export const useFetchCreditCardSaleMutation = () => {
  const mutation = useMutation<
    string,
    AxiosError<ApiError>,
    ApiBasePathParams & {
      invoiceNumber: string;
      omnitokenId?: string | number;
      tokenId?: string;
      expirationMonth?: string;
      expirationYear?: string;
      amount: number;
    },
    unknown
  >(
    async (
      data: ApiBasePathParams & {
        invoiceNumber: string;
        omnitokenId?: string | number;
        tokenId?: string;
        expirationMonth?: string;
        expirationYear?: string;
        amount: number;
      }
    ) => {
      const { orgId, accountId, invoiceNumber, ...payload } = data;

      const path = config.api.worldpayIntegrationService.creditCardSale
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString())
        .replace("{invoiceNumber}", invoiceNumber.toString());

      const response = await client.post(path, { ...payload });

      return response.data.callback;
    }
  );

  return mutation;
};

export const useFetchOmnitokensQuery = (
  orgId: number,
  accountId: number,
  queryOptions?: QueryOptions<string>
) => {
  const fetchOmnitokens = async (): Promise<OmnitokenInfo> => {
    const path = config.api.worldpayIntegrationService.getOmnitokens
      .replace("{orgId}", orgId.toString())
      .replace("{accountId}", accountId.toString());

    const response = await client.get<any>(path);

    return response.data;
  };

  return useQuery(["fetchOmnitokens", orgId, accountId], fetchOmnitokens, {
    ...queryOptions,
    ...DEFAULT_QUERY_OPTIONS,
  });
};

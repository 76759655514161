import { Box, Checkbox, FormControlLabel, Grid2 as Grid } from "@mui/material";
import { DropdownItem, ModalForm, CustomerInfo } from "../../../types";
import { SelectBox, TextField } from "../../common";
import { OrganizationModel, UserModel } from "../../../models";
import { selectedOrgIdAtom, userAtom } from "../../../atoms";
import { useAtom } from "jotai";
import { Role } from "../../../enums";
import { useFetchOrganizationsQuery } from "../../../api";
import { useEffect, useState } from "react";
import { createUserProfileBodySchema } from "../../../validations";
import { useValidateForm } from "../../../hooks";

type FormData = Pick<
  CustomerInfo,
  | "firstName"
  | "lastName"
  | "phoneNumber"
  | "id"
  | "orgId"
  | "emailId"
  | "active"
> & { roleCode: string };

type UserFormProps = ModalForm<CustomerInfo, FormData> & {
  isCreateMode?: boolean;
};

export const UserForm = ({
  id,
  isCreateMode,
  defaultValues,
  handleEdit,
}: UserFormProps) => {
  const [user] = useAtom(userAtom);
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const { register, handleSubmit, errors } = useValidateForm(
    createUserProfileBodySchema
  );
  let userRoleDropDown = UserModel.buildUserRoleDropdown();
  const [organizationsDropdown, setOrganizationsDropdown] = useState<
    DropdownItem[]
  >([]);
  const fetchOrganizationsQuery = useFetchOrganizationsQuery({
    enabled: user?.roleCode === Role.SuperAdmin,
  });

  userRoleDropDown = userRoleDropDown.filter(
    (role) => role.value !== Role.SuperAdmin
  );

  useEffect(() => {
    if (fetchOrganizationsQuery.isSuccess) {
      const dropdown = OrganizationModel.buildOrganizationDropdown(
        fetchOrganizationsQuery.data
      );
      setOrganizationsDropdown(dropdown);
    } else if (fetchOrganizationsQuery.isError) {
      setOrganizationsDropdown([]);
    }
  }, [
    fetchOrganizationsQuery.isSuccess,
    fetchOrganizationsQuery.isError,
    fetchOrganizationsQuery.data,
    fetchOrganizationsQuery.error,
    fetchOrganizationsQuery.isLoading,
  ]);

  const handleEditWrapper = (
    data: Omit<FormData, "id" | "orgId"> & { orgId: any }
  ) => {
    handleEdit({
      ...data,
      id: defaultValues ? defaultValues.id : 0,
    });
  };

  return (
    <Box
      onSubmit={handleSubmit(handleEditWrapper)}
      component={"form"}
      id={id}
      noValidate
      sx={{
        height: "100%",
      }}
    >
      <Grid
        container
        size={12}
        direction={"column"}
        gap={3}
        sx={{
          borderRadius: "10px",
          p: 3,
          mb: 2,
          width: "100%",
        }}
      >
        <Grid size={12}>
          <TextField
            label="First Name"
            name="firstName"
            register={register}
            error={errors["firstName"]}
            defaultValue={defaultValues && defaultValues.firstName}
            maxLength={25}
          />
        </Grid>
        <Grid size={12}>
          <TextField
            label="Last Name"
            name="lastName"
            register={register}
            error={errors["lastName"]}
            defaultValue={defaultValues && defaultValues.lastName}
            maxLength={25}
          />
        </Grid>

        {user?.roleCode === Role.SuperAdmin && (
          <Grid size={12}>
            <SelectBox
              label="Organization"
              name="orgId"
              register={register}
              error={errors["orgId"]}
              dropdown={organizationsDropdown}
              disabled={!isCreateMode}
              valueAsNumber={true}
              defaultValue={
                isCreateMode
                  ? selectedOrgId
                  : defaultValues && defaultValues.orgId
              }
            />
          </Grid>
        )}
        {!isCreateMode && (
          <input
            type="number"
            style={{ display: "none" }}
            {...register("orgId")}
            value={defaultValues?.orgId}
          />
        )}
        {isCreateMode && user?.roleCode !== Role.SuperAdmin && (
          <input
            type="number"
            style={{ display: "none" }}
            {...register("orgId")}
            value={selectedOrgId}
          />
        )}
        <Grid size={12}>
          <SelectBox
            label="Role"
            name="roleCode"
            register={register}
            error={errors["roleCode"]}
            dropdown={userRoleDropDown.filter(
              (item) => item.value !== Role.Customer
            )}
            defaultValue={defaultValues && defaultValues.roleCode}
          />
        </Grid>
        <Grid size={12}>
          <TextField
            label="Email"
            name="emailId"
            register={register}
            error={errors["emailId"]}
            defaultValue={defaultValues && defaultValues.emailId}
            maxLength={45}
          />
        </Grid>
        <Grid size={12}>
          <TextField
            type="number"
            label="Phone Number"
            name="phoneNumber"
            register={register}
            error={errors["phoneNumber"]}
            defaultValue={defaultValues && defaultValues.phoneNumber}
            maxLength={20}
          />
        </Grid>
        <Grid size={12}>
          <FormControlLabel
            control={
              <Checkbox
                {...register("active")}
                defaultChecked={
                  isCreateMode ? true : defaultValues?.active || false
                }
                sx={{
                  height: "20px",
                }}
              />
            }
            label="Active"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

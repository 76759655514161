import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, LinearProgress, Alert, Link } from "@mui/material";
import { Modal } from "./Modal";
import { CreateInvoiceStatus } from "../../../enums";
import { theme } from "../../../utils";
import { useFetchInvoiceGenerationStatus } from "../../../api/invoiceService";

type CreateRegenerateInvoiceModalProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  requestId: string;
  orgId: number;
  accountId: number;
  regenerate?: boolean;
  handleRefresh?: () => void;
};

export const CreateRegenerateInvoiceModal: React.FC<
  CreateRegenerateInvoiceModalProps
> = ({
  open,
  onClose,
  title,
  requestId,
  regenerate,
  orgId,
  accountId,
  handleRefresh,
}) => {
  // eslint-disable-next-line no-undef
  const intervalRef = useRef<NodeJS.Timeout>();
  const [progress, setProgress] = useState(0);
  const [invoiceStatus, setInvoiceStatus] = useState<CreateInvoiceStatus>(
    CreateInvoiceStatus.NotStarted
  );
  const [invoiceNumber, setInvoiceNumber] = useState<string>("");
  const fetchInvoiceGenerationStatus = useFetchInvoiceGenerationStatus(
    requestId,
    orgId,
    accountId,
    false
  );

  const resetAll = () => {
    setProgress(0);
    setInvoiceStatus(CreateInvoiceStatus.NotStarted);
    requestId = "";
    setInvoiceNumber("");
  };

  const handleClose = () => {
    resetAll();
    onClose();
  };

  const checkStatus = () => {
    intervalRef.current = setInterval(async () => {
      if (requestId) {
        const statusResult = await fetchInvoiceGenerationStatus.refetch();
        const status = statusResult?.data?.response.status;

        if (
          status === CreateInvoiceStatus.PdfGenerated ||
          status === CreateInvoiceStatus.Failed
        ) {
          clearInterval(intervalRef.current!);
          intervalRef.current = undefined;
          if (status === CreateInvoiceStatus.PdfGenerated) {
            setProgress(100);
            setInvoiceNumber(statusResult?.data?.response.invoiceNumber || "");
            handleRefresh && handleRefresh();
          } else {
            setInvoiceStatus(CreateInvoiceStatus.Failed);
            setProgress(0);
          }
        }
      }
    }, 3000);
    return () => clearInterval(intervalRef.current!);
  };

  const updateProgress = (status: CreateInvoiceStatus) => {
    setInvoiceStatus(status);
    let progressValue = 0;
    switch (status) {
      case CreateInvoiceStatus.NotStarted:
        progressValue = 0;
        break;
      case CreateInvoiceStatus.InProgress:
        progressValue = Math.min(progress + 20, 100);
        break;
      case CreateInvoiceStatus.PdfGenerated:
        progressValue = 100;
        break;
      case CreateInvoiceStatus.Failed:
        progressValue = 0;
        break;
    }

    setProgress(progressValue);
  };

  useEffect(() => {
    if (fetchInvoiceGenerationStatus.isSuccess) {
      const status = fetchInvoiceGenerationStatus.data?.response.status;
      if (status === CreateInvoiceStatus.PdfGenerated) {
        updateProgress(CreateInvoiceStatus.PdfGenerated);
      } else if (status === CreateInvoiceStatus.Failed) {
        updateProgress(CreateInvoiceStatus.Failed);
      } else {
        updateProgress(CreateInvoiceStatus.InProgress);
      }
    }

    if (fetchInvoiceGenerationStatus.isError) {
      updateProgress(CreateInvoiceStatus.Failed);
    }
  }, [
    fetchInvoiceGenerationStatus.isSuccess,
    fetchInvoiceGenerationStatus.isError,
  ]);

  useEffect(() => {
    if (requestId) {
      fetchInvoiceGenerationStatus.refetch();
      updateProgress(CreateInvoiceStatus.InProgress);
      checkStatus();
    }
  }, [requestId]);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={title}
      content={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "40px",
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
              }}
            >
              {progress === 100
                ? regenerate
                  ? "Invoice regenerated"
                  : "Invoice created"
                : progress === 0
                ? regenerate
                  ? "Failed to regenerate invoice"
                  : "Failed to create invoice"
                : regenerate
                ? "Regenerating invoice..."
                : "Creating invoice..."}
            </Typography>

            <Box>
              {invoiceStatus === CreateInvoiceStatus.Failed ? (
                <Alert severity="error" sx={{ marginTop: 2 }}>
                  {regenerate
                    ? "An error occurred during the regeneration. Please try again."
                    : "An error occurred during the creation. Please try again."}
                </Alert>
              ) : invoiceNumber ? (
                <Typography
                  sx={{
                    color: "#6c757d",
                  }}
                >
                  Invoice Number:{"  "}
                  <Link
                    href={`${window.location.origin}/invoices/${invoiceNumber}?orgId=${orgId}&accountId=${accountId}`}
                    sx={{
                      textDecoration: "none",
                      textDecorationLine: "underline",
                      fontSize: "16px",
                      width: "100%",
                      textAlign: "right",
                    }}
                  >
                    {invoiceNumber}
                  </Link>
                </Typography>
              ) : (
                <Typography
                  sx={{
                    color: "#6c757d",
                  }}
                >
                  Please wait, this may take a few moments.
                </Typography>
              )}
            </Box>

            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{
                width: "100%",
                height: 10,
                borderRadius: 5,
                backgroundColor: "#e0e0e0",
                "& .MuiLinearProgress-bar": {
                  backgroundColor:
                    progress < 100 ? theme.palette.primary.dark : "#4caf50",
                },
              }}
            />

            <Typography
              sx={{
                marginTop: 1,
                color: progress < 100 ? "#6c757d" : "#4caf50",
                fontWeight: 500,
              }}
            >
              {progress}% completed
            </Typography>
          </Box>
        </Box>
      }
      sx={{
        modal: {
          width: { lg: "40%", xs: "80%", sm: "80%" },
        },
        footer: { justifyContent: "flex-end" },
      }}
    />
  );
};
